<template>
  <transition name="component-fade" mode="out-in">
    <div key="loader" v-if="loading" class="quiz-student-loader">
      <div>
        <Loader />
      </div>
    </div>
    <div v-else class="video-player">
      <div class="plyr plyr__video-embed">
        <iframe
          ref="player"
          :src="linkSrc"
          allowfullscreen
          allowtransparency
          allow="autoplay"
        ></iframe>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import urlParser from 'js-video-url-parser';
import YouTubePlayer from 'youtube-player';
import VimeoPlayer from '@vimeo/player';
import Loader from '@/components/Loader/Loader.vue';
import ActivityTimeMetrics from '@/utils/activityTimeMetrics';
import 'plyr/dist/plyr.css';
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'VideoPlayer',
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    completeThreshold: 0.95,
    player: null,
    playerYoutube: null,
    duration: null,
    checkProgress: null,
    isPlaying: false,
    timeMetrics: null,
  }),
  components: { Loader },
  computed: {
    ...mapGetters({
      loading: 'viewer/getIsLoading',
    }),
    /*
     * return { mediaType: string, id: string, provider: string }
     */
    parsedLink() {
      if (this.isRutube) {
        const link = this.activity.meta.url;
        const url = new URL(link);
        if (url.hostname === 'rutube.ru') {
          const params = url.pathname.split('/').filter((v) => v.length);
          return {
            id: params[params.length - 1],
            mediaType: 'video',
            provider: 'rutube',
          };
        }
      }
      return urlParser.parse(this.activity.meta.url);
    },
    isYoutubeLink() {
      return this.parsedLink?.provider === 'youtube';
    },
    isRutube() {
      return this.activity.meta.url.includes('rutube');
    },
    linkSrc() {
      if (this.isYoutubeLink) {
        return `https://www.youtube.com/embed/${this.parsedLink.id}?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`;
      }
      if (this.isRutube) {
        return `https://rutube.ru/play/embed/${this.parsedLink.id}`;
      }
      return `https://player.vimeo.com/video/${this.parsedLink.id}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`;
    },
  },
  mounted() {
    this.player = this.$refs.player;
    if (this.isRutube) {
      this.player.addEventListener('load', this.handleRutubeEvents);
    } else if (this.isYoutubeLink) {
      this.playerYoutube = YouTubePlayer(this.$refs.player);
      this.playerYoutube.on('stateChange', this.handleYoutubeEvents);
    } else {
      const vimPlayer = new VimeoPlayer(this.$refs.player);
      vimPlayer.on('play', () => {
        this.isPlaying = true;
        this.setStatisticStarted();
      });
      vimPlayer.on('pause', () => {
        this.isPlaying = false;
      });
      vimPlayer.on('ended', () => {
        this.isPlaying = false;
      });
      vimPlayer.on('timeupdate', (event) => {
        if (event.percent >= this.completeThreshold) {
          this.setStatisticCompleted();
        }
      });
    }
    this.timeMetrics = new ActivityTimeMetrics(this.activity);
  },
  methods: {
    ...mapActions('courseContentStudent', ['setActivityAsCompleted', 'setActivityAsStarted']),
    ...mapActions('viewer', ['setLoading']),
    setStatisticStarted() {
      if (this.activity.statistic.status === ActivityStatuses.notStarted) {
        this.setActivityAsStarted(this.activity._id);
      }
    },
    setStatisticCompleted() {
      if (this.activity.statistic.status !== ActivityStatuses.done) {
        this.setActivityAsCompleted(this.activity._id);
      }
    },
    async handleYoutubeEvents(event) {
      if (event.data === -1) {
        this.duration = await event.target.getDuration();
      }
      if (event.data === 1) {
        this.isPlaying = true;
        this.setStatisticStarted();
        this.checkProgress = setInterval(async () => {
          const playTime = await event.target.getCurrentTime();
          if (playTime >= this.duration * this.completeThreshold) {
            this.setStatisticCompleted();
            clearInterval(this.checkProgress);
          }
        }, 1000);
      }
      if (event.data === 2) {
        this.isPlaying = false;
        clearInterval(this.checkProgress);
      }
      if (event.data === 0) {
        this.isPlaying = false;
      }
    },
    handleRutubeEvents() {
      window.addEventListener('message', this._handleRutubeEvents);
    },
    _handleRutubeEvents(e) {
      try {
        const message = JSON.parse(e.data);
        if (message.type === 'player:durationChange') {
          this.duration = Math.floor(message.data.duration);
        }
        if (message.type === 'player:changeState' && message.data.state === 'playing') {
          this.isPlaying = true;
          this.setStatisticStarted();
        }
        if (message.type === 'player:changeState' && (
          message.data.state === 'paused'
          || message.data.state === 'stopped')) {
          this.isPlaying = false;
        }
        if (message.type === 'player:currentTime' && this.activity.statistic.status === ActivityStatuses.doing) {
          if (this.duration && message.data.time >= this.duration * this.completeThreshold) {
            this.setStatisticCompleted();
          }
        }
        // eslint-disable-next-line no-empty
      } catch (_) {}
    },
  },
  watch: {
    'activity._id': {
      async handler(id, oldId) {
        if (id !== oldId) {
          this.setLoading(false);
        }
      },
      immediate: true,
    },
    isPlaying(value) {
      if (!this.timeMetrics) {
        return;
      }
      if (value) {
        this.timeMetrics.ignoreInactive = true;
        return;
      }
      this.timeMetrics.ignoreInactive = false;
    },
  },
  beforeDestroy() {
    if (this.player.removeEventListener) {
      this.player.removeEventListener('load', this.handleRutubeEvents);
    }
    window.removeEventListener('message', this._handleRutubeEvents);
    if (this.timeMetrics && this.timeMetrics.destroy) {
      this.timeMetrics.destroy();
    }
  },
};
</script>
