<template>
  <transition name="component-fade" mode="out-in">
    <div key="loader" v-if="loading" class="quiz-student-loader">
      <div>
        <Loader />
      </div>
    </div>
    <div v-else class="quiz-player">
      <ResultQuiz
        v-if="showResult && questions.length"
        @viewAnswers="viewAnswers"
        :restartQuiz="restartThisQuiz"
      />
      <QuestionCardStudent
        v-else
        :quiz="activity"
        :question="currentQuestion"
        :questionsQuantity="questions.length"
        :quizStatisticId="quizStatisticId(activity)"
        :quizId="activity._id"
        :currentQuestionIndex="currentQuestionIndex"
        :nextQuestion="nextQuestion"
        :previousQuestion="previousQuestion"
        :sendAnswers="sendAnswers"
        :showResult="() => (this.showResult = true)"
      />
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ResultQuiz from '@/components/Cards/ResultQuiz.vue';
import QuestionCardStudent from '@/components/Cards/QuestionCardStudent.vue';
import Loader from '@/components/Loader/Loader.vue';
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'QuizPlayer',
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  components: { Loader, ResultQuiz, QuestionCardStudent },
  data: () => ({
    showResult: false,
    currentQuestionIndex: 0,
  }),
  computed: {
    ...mapGetters('courseContentStudent', ['questions', 'isQuizCompleted']),
    ...mapGetters({
      loading: 'viewer/getIsLoading',
    }),
    quizId() {
      if (this.activity?.type === 'quiz') {
        return this.activity._id;
      }
      return null;
    },
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
  },
  methods: {
    ...mapActions('toaster', ['setToaster']),
    ...mapActions('courseContentStudent', ['setQuizId', 'sendAnswers', 'restartQuiz', 'setActivityAsCompleted']),
    ...mapActions('viewer', ['setLoading']),
    async restartThisQuiz() {
      try {
        await this.restartQuiz({
          quizId: this.activity._id,
          parentStatisticId: this.activity.statistic._id,
        });
        this.showResult = false;
        this.currentQuestionIndex = 0;
      } catch (_) {
        this.setToastError();
      }
    },
    quizStatisticId(activity) {
      return activity.statistic?._id;
    },
    viewAnswers() {
      this.currentQuestionIndex = 0;
      this.showResult = false;
    },
    nextQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex += 1;
      }
    },
    previousQuestion() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex -= 1;
      }
    },
    setToastError() {
      const toast = {
        title: this.$t('errorMessages.somethingWentWrong'),
        body: this.$t('errorMessages.tryAgain'),
      };
      this.setToaster({
        type: 'toast-danger',
        toast,
      });
    },
    // set quiz as completed if quiz does not have questions
    checkQuizForCompletion() {
      if (!this.questions.length && this.activity.statistic.status !== ActivityStatuses.done) {
        this.setActivityAsCompleted(this.activity._id);
      }
    },
  },
  watch: {
    quizId: {
      async handler(id, oldId) {
        if (id !== oldId) {
          this.setQuizId(null);
          this.currentQuestionIndex = 0;
          this.showResult = false;
        }

        if (id) {
          this.setQuizId(id);
          this.checkQuizForCompletion();

          // set index of the first question which is not completed
          const firstNotCompletedQuestionIndex = this.questions.findIndex(
            (q) => q.statistic.status === ActivityStatuses.notStarted,
          );
          if (firstNotCompletedQuestionIndex !== -1) {
            this.currentQuestionIndex = firstNotCompletedQuestionIndex;
          }

          this.showResult = false;
          // show result page of quiz if it's completed
          if (
            this.questions.filter((q) => q.statistic.status === ActivityStatuses.done).length
            === this.questions.length
          ) {
            this.showResult = true;
          }

          this.setLoading(false);
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.setQuizId(null);
  },
};
</script>
