<template>
  <div
    class="task-student-container"
    :class="{ 'task-student-container--height-slim': isHomeworkSend }"
  >
    <div
      v-if="isHomeworkSend"
      class="complete-card"
      :class="{ 'complete-card--narrow': $mq === 'lg' && !isHiddenMaterialList.value }"
    >
      <ProgressStatus
        :status="status"
        iconPosition="left"
        class="complete-card__progress-status"
        :class="{ 'complete-card__progress-status--centered': !isAttemptsShown }"
      />
      <div class="complete-card__title">{{ $t(statusMessage) }}</div>
      <div class="complete-card__attempts">
        <span v-if="isAttemptsShown">
          {{ $t('label.attempt') }}
          {{ attemptCounter }}
        </span>
      </div>
    </div>
    <div class="w-100" v-else>
      <transition name="fade-300" mode="out-in">
        <div key="loader" class="task-loader" v-if="isSending && !isHomeworkSend">
          <Loader disableScale />
          <p class="loader">
            {{ $t('supportText.sendingTheTask') }}
            <span class="task-loader__dot">.</span>
            <span class="task-loader__dot">.</span><span class="task-loader__dot">.</span>
          </p>
        </div>
        <div
          key="answer-to-task"
          v-else
          class="task-student"
          :class="{ 'task-student--narrow': $mq === 'lg' && !isHiddenMaterialList.value }"
        >
          <div class="card-title">
            {{ $t('supportText.writeYourAnswer') }}
          </div>
          <div class="textarea-container">
            <b-form-textarea
              id="textarea-default"
              :placeholder="$t('placeholders.writeYourAnswerHere')"
              class="task-textarea scroll"
              no-resize
              v-model="selectedMaterial.text"
            ></b-form-textarea>
          </div>
          <div class="task-student__file-link">
            <a
              :href="`https://${activity.meta.url}`"
              target="_blank"
              rel="noopener noreferrer"
              class="material-link"
              v-if="activity.meta.url"
            >
              <span>{{ $t('links.attachedFile') }}</span>
              <PaperClipIcon />
            </a>
            <div v-if="isAttemptsShown">
              {{ $t('label.attempt') }}
              {{ attemptCounter }}
            </div>
          </div>

          <div class="button-container">
            <AddButton
              v-if="!isFileSelected"
              variant="secondary"
              :title="$t('buttonLabels.attachFile')"
              :size="$mq === 'lg' ? 'small' : ''"
              @click="loadMaterial"
            />
            <div class="remove-document" v-else @click="loadMaterial">
              <div class="left">
                <div>
                  <FileIcon />
                </div>
                <span>{{ selectedMaterial.fileName }}</span>
              </div>
              <div @click.stop="remove">
                <WhiteCrossIcon />
              </div>
            </div>
            <AddButton
              :title="$t('buttonLabels.sendAnswer')"
              :size="$mq === 'lg' ? 'small' : ''"
              :disabled="!(selectedMaterial.text.length || selectedMaterial.fileName)"
              @click="sendAnswer"
            />
          </div>
          <input
            type="file"
            id="imageLoader"
            class="d-none"
            @change="upload"
            ref="load"
            :accept="acceptedTypes"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PaperClipIcon from '@/components/Icons/PaperClipIcon.vue';
import FileIcon from '@/components/Icons/FileIcon.vue';
import WhiteCrossIcon from '@/components/Icons/WhiteCrossIcon.vue';
import AddButton from '@/components/Buttons/AddButton.vue';
import constantsMixin from '@/mixins/constants.mixin';
import Loader from '@/components/Loader/Loader.vue';
import ProgressStatus from '@/components/ProgressStatus.vue';
import StudentService from '@/services/student.service';
import MaterialService from '@/services/material.service';
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'TaskCarsStudent',
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  components: {
    PaperClipIcon,
    AddButton,
    FileIcon,
    WhiteCrossIcon,
    Loader,
    ProgressStatus,
  },
  mixins: [constantsMixin],
  data: () => ({
    fileSizeLimit: 100 * 1024 * 1024, // 100 Megabyte
    selectedMaterial: { text: '' },
    formData: null,
    isSending: false,
  }),
  inject: {
    isHiddenMaterialList: {
      default: {
        value: false,
      },
    },
  },
  computed: {
    acceptedTypes() {
      return `.${this.pdf}, .${this.docx}, .${this.pptx},  .${this.zip}`;
    },
    whiteListExtensions() {
      return [this.pdf, this.pptx, this.docx, this.zip];
    },
    isFileSelected() {
      return !!this.selectedMaterial?.fileName;
    },
    isHomeworkSend() {
      return this.activity.statistic.status !== ActivityStatuses.notStarted;
    },
    status() {
      if (this.activity.statistic.status === ActivityStatuses.doing) {
        return {
          value: 'not-started',
          label: 'label.underReview',
        };
      }
      if (this.activity.statistic.status === ActivityStatuses.done) {
        return {
          value: 'done',
          label: 'label.completed',
        };
      }
      if (this.activity.statistic.status === ActivityStatuses.rejected) {
        return {
          value: 'rejected',
          label: 'label.rejected',
        };
      }
      return {
        value: 'not-started',
        label: 'label.underReview',
      };
    },
    statusMessage() {
      if (this.activity.statistic.status === ActivityStatuses.doing) {
        return 'label.taskIsSent';
      }
      if (this.activity.statistic.status === ActivityStatuses.done) {
        return 'label.taskIsCompleted';
      }
      if (this.activity.statistic.status === ActivityStatuses.rejected) {
        return 'label.taskIsRejected';
      }
      return 'label.taskIsSent';
    },
    activityContext() {
      const routeIds = Object.values(this.$route.params);
      routeIds.reverse();
      return routeIds;
    },
    usedAttempts() {
      return this.activity.statistic.meta?.used_attempts || 0;
    },
    attemptCounter() {
      const attemptTry = this.usedAttempts + 1;
      const maxAttempt = this.activity.meta?.attempts;
      if (attemptTry >= maxAttempt) {
        return `${maxAttempt}/${maxAttempt}`;
      }
      return `${attemptTry}/${maxAttempt}`;
    },
    isAttemptsShown() {
      return this.activity.meta?.attempts >= 1;
    },
  },
  methods: {
    ...mapActions({ setToaster: 'toaster/setToaster' }),
    ...mapActions('courseContentStudent', ['setActivityAsCompleted', 'setActivityAsStarted']),
    validateFileExtension(file) {
      const spiltName = file.name.split('.');
      const flExt = spiltName[spiltName.length - 1];
      if (!this.whiteListExtensions.includes(flExt)) {
        const toast = {
          title: this.$t('errorMessages.fileNotSupported'),
          body: `${this.$t('supportText.onlyOfTheFormat')} .${this.pptx}, .${this.docx}, .${
            this.pdf
          }, .${this.zip},`,
        };
        this.setToaster({
          type: 'toast-danger',
          toast,
        });
        return false;
      }
      return true;
    },
    validateFileSize(file) {
      if (this.fileSizeLimit < file?.size) {
        const toast = {
          title: this.$t('errorMessages.sizeToLarge'),
          body: `${this.$t('supportText.maximumFileSize')} - ${this.formatBytes(
            this.fileSizeLimit,
          )}`,
        };
        this.setToaster({
          type: 'toast-danger',
          toast,
        });
        return false;
      }
      return true;
    },
    loadMaterial() {
      this.$refs.load.click();
    },
    upload(event) {
      const file = this.$refs.load.files[0];
      if (file) {
        if (!this.validateFileExtension(file)) {
          return;
        }
        if (!this.validateFileSize(file)) {
          return;
        }

        this.formData = new FormData();
        this.formData.append('file', file);

        let files = [];
        files = Array.from(event.target.files);

        files.forEach((f) => {
          const reader = new FileReader();
          const splitNam = f.name.split('.');
          reader.onload = (ev) => {
            const mime = ev.target.result.split(';')[0].split('data:')[1];
            this.formData.append('type', mime);
            this.selectedMaterial = {
              ...this.selectedMaterial,
              fileSrc: ev.target.result,
              fileName: f.name,
              fileExtension: splitNam[splitNam.length - 1],
              fileSize: this.formatBytes(f.size),
            };
          };

          reader.readAsDataURL(f);
        });
      }
    },
    formatBytes(a, b = 2) {
      if (a === 0) return '0 Bytes';
      const c = b < 0 ? 0 : b;
      const d = Math.floor(Math.log(a) / Math.log(1024));
      return `${parseFloat((a / 1024 ** d).toFixed(c))} ${
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
      }`;
    },
    remove() {
      this.selectedMaterial = { text: this.selectedMaterial.text };
      if (this.$refs.load?.value) {
        this.$refs.load.value = null;
      }
    },
    async sendAnswer() {
      if (this.selectedMaterial.fileName) {
        this.selectedMaterial.file = this.formData;
      }
      this.isSending = true;
      if (!this.selectedMaterial.fileName) {
        StudentService.startChat({
          activity_id: this.activity._id,
          activity_context: this.activityContext,
        })
          .then(() => {
            StudentService.sendChatMessageByStudent({
              activity_id: this.activity._id,
              activity_context: this.activityContext,
              text: this.selectedMaterial.text,
              attachment_url: '',
            })
              .then(() => {
                setTimeout(() => {
                  this.setActivityAsStarted(this.activity._id);
                }, 500);
              })
              .catch(() => {});
          })
          .catch(() => {
            this.isSending = false;
            this.serverErrorToast();
          });
      } else {
        this.uploadFile(this.selectedMaterial.file).then((link) => {
          StudentService.startChat({
            activity_id: this.activity._id,
            activity_context: this.activityContext,
          })
            .then(() => {
              StudentService.sendChatMessageByStudent({
                activity_id: this.activity._id,
                activity_context: this.activityContext,
                text: this.selectedMaterial.text,
                attachment_url: link,
                attachment_name: this.selectedMaterial.fileName,
              })
                .then(() => {
                  setTimeout(() => {
                    this.setActivityAsStarted(this.activity._id);
                  }, 500);
                })
                .catch(() => {});
            })
            .catch(() => {
              this.isSending = false;
              this.serverErrorToast();
            });
        });
      }
    },
    uploadFile(answer) {
      return MaterialService.uploadFile(answer)
        .then(async ({ data: { url } }) => url)
        .catch(() => {});
    },
    serverErrorToast() {
      const toast = {
        title: this.$t('errorMessages.somethingWentWrong'),
        body: this.$t('errorMessages.tryAgain'),
      };
      this.setToaster({
        type: 'toast-danger',
        toast,
      });
    },
  },
};
</script>
