<template>
  <div class="viewer-page">
    <div id="navigationBack" class="viewer-page__navigation-back">
      <BackButton :label="$t('buttonLabels.backToCourse')" />
    </div>
    <div class="viewer-page__content scroll">
      <div
        class="viewer-page__list-block"
        :class="{ 'viewer-page__list-block--hidden': isHiddenMaterialList }"
      >
        <MaterialListViewer
          :loading="loadingListOfContent"
          :isHiddenMaterialList.sync="isHiddenMaterialList"
          :selectMaterial="selectMaterial"
          :lastActiveActivityId="lastActiveActivityId"
        />
      </div>
      <transition name="component-fade" mode="out-in">
        <div class="viewer-page__overlay" v-if="!isHiddenMaterialList && $mq != 'xxl'" />
      </transition>
      <div
        class="viewer-page__viewer viewer"
        :class="{ pl4: isHiddenMaterialList && $mq === 'xxl' }"
      >
        <div id="navigationBackMobile" class="viewer-page__navigation-mobile">
          <BackButton />
          <ListButton @click="isHiddenMaterialList = false" />
        </div>

        <div v-if="isHiddenMaterialList" class="viewer__action">
          <ListButton @click="isHiddenMaterialList = false" />
        </div>
        <div class="viewer__container" :class="{ scroll: !$device.isMobile }">
          <div class="viewer-info d-flex container">
            <div v-if="selectedActivity" class="viewer__info">
              <div class="viewer__title">{{ selectedActivity.name }}</div>
              <div class="viewer__description">
                {{ materialText }}
              </div>
            </div>
          </div>
          <transition name="component-fade" mode="out-in">
            <div
              v-if="loading && !this.$device.isMobile"
              key="loading"
              class="viewer-loader d-none"
            >
              <div>
                <Loader />
              </div>
            </div>
          </transition>
          <transition name="component-fade" mode="out-in">
            <ViewerPlayer
              :key="selectedActivity._id"
              v-if="selectedActivity"
              :activity="selectedActivity"
              :isHiddenMaterialList="isHiddenMaterialList"
              :class="{ 'doc-height-limit': selectedActivity.type === 'material' }"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MaterialListViewer from '@/components/Viewer/MaterialListViewer.vue';
import ListButton from '@/components/Buttons/ListButton.vue';
import BackButton from '@/components/Buttons/BackButton.vue';
import ViewerPlayer from '@/components/Viewer/ViewerPlayer.vue';
import deviceMixin from '@/mixins/device.mixin';
import Loader from '@/components/Loader/Loader.vue';
import { lastActiveActivity } from '@/utils/helpers';
import StudentService from '@/services/student.service';
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'Viewer',
  components: {
    Loader,
    ViewerPlayer,
    MaterialListViewer,
    BackButton,
    ListButton,
  },
  data() {
    return {
      loadingListOfContent: true,
      isHiddenMaterialList: false,
      lastActiveActivityId: null,
    };
  },
  mixins: [deviceMixin],
  async created() {
    this.isHiddenMaterialList = !this.$device.isDesktop;
    const { courseId, programId } = this.$route.params;
    if (!this.content) {
      await this.getCourseContent({ programId, courseId });
    }

    this.checkCourseForCompletion();

    if (this.$route.query.activityId) {
      this.selectActivityId(this.$route.query.activityId);
      this.lastActiveActivityId = this.$route.query.activityId;
    } else {
      this.setInitialActivity();
    }

    this.loadingListOfContent = false;
  },
  computed: {
    ...mapGetters('courseContentStudent', ['content', 'selectedActivity']),
    ...mapGetters({
      course: 'viewer/getCourseActivity',
      material: 'viewer/getSelectedContent',
      pageCount: 'materialStudent/getPagesCounter',
      currentPage: 'materialStudent/getCurrentPage',
      materialIndex: 'viewer/getContentIndex',
      loading: 'viewer/getIsLoading',
    }),

    combined() {
      return {
        pageCount: this.pageCount,
        currentPage: this.currentPage,
        selectedActivity: this.selectedActivity,
        isLoading: this.loading,
      };
    },
    materialText() {
      if (this.selectedActivity?.description) {
        return this.selectedActivity.description;
      }
      if (this.selectedActivity.meta?.text) {
        return this.selectedActivity.meta.text;
      }
      return '';
    },
  },
  methods: {
    ...mapActions({
      getCourseContent: 'courseContentStudent/fetchCourseContent',
      selectActivityId: 'courseContentStudent/selectActivityId',
      setActivityAsStarted: 'courseContentStudent/setActivityAsStarted',
      setActivityAsCompleted: 'courseContentStudent/setActivityAsCompleted',
    }),
    ...mapActions('viewer', [
      'fetchCourseActivity',
      'fetchCourseContent',
      'setSelectedContentIndex',
      'resetCourseContent',
      'setLoading',
    ]),
    setInitialActivity() {
      const lastActivity = lastActiveActivity(this.content);
      if (lastActivity) {
        this.selectActivityId(lastActivity._id);
        this.lastActiveActivityId = lastActivity._id;
      }
    },
    selectMaterial(item) {
      if (item.activity.statistic.is_blocked) {
        // return if activity is blocked
        return;
      }
      if (item.activity.type === 'group') {
        return;
      }
      const id = item.activity._id;
      if (this.$route.query.activityId === id) return;
      if (this.selectedActivity?._id === id) return;
      this.setLoading(true);
      this.selectActivityId(id);

      this.$router.replace({ query: { activityId: id } });

      this.$store.dispatch('materialStudent/setCurrentPage', 1);
      if (!this.$device.isDesktop) {
        this.isHiddenMaterialList = true;
      }
    },
    checkCourseForCompletion() {
      if (!this.content?.children || !this.content?.children?.length) {
        if (this.content.activity.statistic.status !== ActivityStatuses.done) {
          StudentService.setActivityCompleted(this.content.activity._id);
        }
      }
    },
  },
  watch: {
    // mark material as done
    combined: {
      handler(val) {
        if (
          val.currentPage === val.pageCount
          && !val.isLoading
          && val.selectedActivity?.type === 'material'
        ) {
          if (val.selectedActivity.statistic.status !== ActivityStatuses.done) {
            this.setActivityAsCompleted(val.selectedActivity._id);
          }
        }
      },
      deep: true,
    },
    // mark material as started
    selectedActivity: {
      handler(activity) {
        if (activity?.statistic.status === ActivityStatuses.notStarted && activity?._id && activity.type === 'material') {
          this.setActivityAsStarted(activity._id);
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.selectActivityId(null);
    this.resetCourseContent();
  },
};
</script>
