<template>
  <transition name="component-fade" mode="out-in">
    <div key="loader" v-if="loading" class="quiz-student-loader">
      <div>
        <Loader />
      </div>
    </div>
    <div class="task-player" v-else>
      <TaskCardStudent :activity="activity" />
      <a
        v-if="activity.meta.url && isHomeworkSend"
        :href="`https://${activity.meta.url}`"
        target="_blank"
        rel="noopener noreferrer"
        class="material-link"
        :class="{ 'material-link__delayed-appear': isHomeworkSend }"
      >
        <span>{{ $t('links.attachedFile') }}</span>
        <PaperClipIcon />
      </a>
      <transition name="component-fade" mode="out-in">
        <div class="student-chat" v-if="isHomeworkSend">
          <TaskChat isVisible chatType="student" :activity="activity" />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TaskCardStudent from '@/components/Cards/TaskCardStudent.vue';
import Loader from '@/components/Loader/Loader.vue';
import PaperClipIcon from '@/components/Icons/PaperClipIcon.vue';
import TaskChat from '@/components/TaskChat/TaskChat.vue';
import ActivityTimeMetrics from '@/utils/activityTimeMetrics';
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'TaskPlayer',
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  components: {
    TaskCardStudent,
    Loader,
    PaperClipIcon,
    TaskChat,
  },
  data: () => ({
    showResult: false,
    taskIsSending: false,
    createdChat: null,
    timeMetrics: null,
  }),
  computed: {
    ...mapGetters({
      loading: 'viewer/getIsLoading',
    }),
    isHomeworkSend() {
      return this.activity.statistic.status !== ActivityStatuses.notStarted;
    },
  },
  mounted() {
    this.timeMetrics = new ActivityTimeMetrics(this.activity);
  },
  methods: {
    ...mapActions('viewer', ['setLoading']),
  },
  watch: {
    'activity._id': {
      async handler(id, oldId) {
        if (id !== oldId) {
          setTimeout(() => {
            this.setLoading(false);
          }, 150);
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.timeMetrics && this.timeMetrics.destroy) {
      this.timeMetrics.destroy();
    }
  },
};
</script>
